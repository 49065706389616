<template lang="pug">
.rbody
    CTDefaultSidebar
        AdminDefaultSidebar

    .rmain(
        ref="fullPage"
    )
        .rmainbox.is-scrollbar-hidden
            CTPusherBeam
            CTDefaultNavbar(
                :page="page"
                :icon="icon"
                :cream="cream"
                :color="color"
            )

                template(v-if="$slots.navbar_left" #navbar_left)
                    slot(name="navbar_left")

                template(v-if="!$slots.navbar_left" #navbar_left_default)
                    .h-full.flex.hidden(
                        class="sm:block"
                        :class="`bg-${color}-50 dark:bg-neutral-900/50`"
                        v-if="icon !== ''"
                    )
                        .flex.items-center.h-full.px-4(
                        )
                            UIcon.h-6.w-6(:class="`text-${color}-500`" :name="icon")
                    .flex.flex-1.h-full(:class="{'ms-0 sm:ms-4': icon !== '', 'ms-4': icon === ''}")
                        .flex.self-center.overflow-hidden(v-if="!$slots.navbar")
                            .text-ellipsis.font-medium.text-sm {{ page }}

                template(v-if="$slots.navbar_right" #navbar_right)
                    slot(name="navbar_right")

            .flex.relative.flex-1.relative.top-0.left-0.w-full.overflow-hidden.z-5
                .flex.flex-1.sticky.top-0.z-5.hidden(
                    v-if="$slots.sidebar"
                    class="md:block"
                )
                    .flex.sticky.overflow-y-auto.scrollbar-sm(class="w-[230px]")
                        .mx-auto.flex.flex-1(
                            ref="asideVH"
                        )
                            aside.relative.left-0.overflow-y-auto.top-0.hidden.bg-white.border-r.border-cream-300(
                                class="md:block dark:bg-neutral-800 w-[230px] max-w-[230px] dark:border-neutral-700/80"

                            )
                                .pb-10.space-y-4
                                    slot(name="sidebar")

                                Teleport(to="body")
                                    USlideover(v-model="openMenu" side="left" :ui="{width: 'max-w-[260px]'}")
                                        .w-full.block
                                            slot(name="sidebar")

                //- ++++++++++++++++++++++++++++
                //- mainbox
                //- ++++++++++++++++++++++++++++
                .w-full.sticky.overflow-y-auto.scrollbar-sm(
                    ref="wMainBox"
                )
                    .w-full.sticky.z-10(v-if="$slots.title")
                        .flex.gap-4.py-3.px-4.flex-1.bg-white.justify-between.w-full.border-b.border-neutral-300.flex-col(
                            class="dark:bg-neutral-800 dark:border-neutral-900/70 sm:flex-row"
                        )
                            .flex.self-center.flex-1
                                slot(name="title")
                            .flex.border-t.border-t-neutral-200.items-center.justify-start.py-2(
                                    class="sm:py-0 sm:justify-end dark:border-t-neutral-900/70 sm:border-none"
                                    v-if="$slots.toolbar"
                                )
                                    slot(name="toolbar")

                        .flex.flex-1.bg-white.px-4.py-2(
                            class="dark:bg-neutral-800 dark:border-neutral-900/70"
                            v-if="$slots.filterbar"
                        )
                            slot(name="filterbar")

                    .w-full.sticky.top-0.left-0.z-10(
                        v-else-if="titleIcon || title"
                    )
                        .flex.flex-1.bg-white.justify-between.w-full.border-b.border-neutral-200.flex-col(
                            class="dark:bg-neutral-800 dark:border-neutral-900/70 sm:flex-row"
                        )
                            .flex.flex-1.px-4.gap-2.flex-col(class="sm:gap-2 sm:flex-row" :class="{'py-3': $slots.toolbar}")
                                .flex.flex-1.gap-2(:class="{'py-3': !$slots.toolbar}")
                                    .flex.block.me-2.h-full(
                                        v-if="$slots.sidebar"
                                        class="md:hidden"
                                        @click="openMenu = true"
                                    )
                                        UIcon.w-6.h-6.cursor-pointer(
                                            name="mdi-chevron-down"
                                        )
                                    .flex.self-center(v-if="titleIcon !== ''")
                                        UIcon(:name="titleIcon")
                                    .flex.flex-1.self-center
                                        .font-medium.text-md.truncate(class="sm:text-sm") {{ title }}

                            .flex.border-t.border-t-neutral-200.px-4.items-center.justify-start.py-2(
                                class="sm:py-0 sm:justify-end dark:border-t-neutral-900/70 sm:border-none"
                                v-if="$slots.toolbar"
                            )
                                slot(name="toolbar")

                        .flex.flex-1.bg-white.px-4.py-2(
                            class="dark:bg-neutral-800"
                            v-if="$slots.filterbar"
                        )
                            slot(name="filterbar")

                    .w-full.animate-fadeIn(
                        ref="mainBoxed"
                    )

                        section(
                            v-if="boxed"
                            class="pl-[calc(50%-calc(980px/2))] pr-[calc(50%-calc(980px/2))]"
                        )
                            slot

                        section(
                            v-else
                        )
                            slot
    Teleport(to="body")
        USlideover(v-model="coreStore.sidebarMob" side="left" :ui="{width: 'max-w-[260px]'}")
            .flex.flex-1.flex-col(ref="target" class="max-w-[260px]")
                CTDefaultSidebarSlideOver
                    AdminDefaultSidebar
</template>
<script setup lang="ts">
import { onClickOutside, useWindowSize } from "@vueuse/core";
import { useCTStore } from "@stores/store";
import { DefaultSidebar } from "#components";
const authStore = UseAuthAdminStore();
const coreStore = useCTStore();
const target = ref(null);
onClickOutside(target, (event) => {
    coreStore.sidebarMob = false;
});

const { width, height } = useWindowSize();
const props = defineProps({
    page: {
        type: String,
        default: "",
    },
    title: {
        type: String,
        default: "",
    },
    icon: {
        type: String,
        default: "",
    },
    titleIcon: {
        type: String,
        default: "",
    },
    color: {
        type: String,
        default: "",
    },
    boxed: {
        type: Boolean,
        default: false,
    },
    cream: {
        type: Boolean,
        default: false,
    },
});
const config = useRuntimeConfig();
useHead({
    title:
        props.title != ""
            ? `${props.title} - ${config?.public?.site_name}`
            : `${config?.public?.site_name}`,
});

const openMenu = ref(false);
const wMainBox = ref(null);
const fullPage = ref(null);
const asideVH = ref(null);
const mainBoxed = ref(null);

const setwidth = () => {
    if (document) {
        const w =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

        if (fullPage.value) {
            const fp = fullPage.value.getBoundingClientRect();

            if (asideVH.value) {
                const sD = asideVH.value.getBoundingClientRect();
                asideVH.value.style.height = `${fp.height}px`;
                asideVH.value.style.minHeight = `${fp.height}px`;
                asideVH.value.style.maxHeight = `${fp.height}px`;

                const xW = w - sD.left;

                fullPage.value.style.width = `${xW}px`;
                fullPage.value.style.minWidth = `${xW}px`;
                fullPage.value.style.maxWidth = `${xW}px`;
            } else {
                fullPage.value.style.maxWidth = `${w - fp.left}px`;
                fullPage.value.style.minWidth = `${w - fp.left}px`;
                fullPage.value.style.width = `${w - fp.left}px`;
            }
        }
        // const fx = mainBoxed.value.getBoundingClientRect();
    }
};

onMounted(() => {
    setwidth();
    setTimeout(() => {
        setwidth();
    }, 800);

    window.addEventListener("resize", () => {
        setwidth();
        setTimeout(() => {
            setwidth();
        }, 300);
    });
});

coreStore.$subscribe((mutation, state) => {
    setwidth();
    setTimeout(() => {
        setwidth();
    }, 300);
});

onBeforeMount(async () => {
    await authStore.GetMe();
});
</script>
